.spinner
{
    animation: spin infinite 0.6s cubic-bezier(.61,.34,.41,.66);
    line-height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes spin 
{
    from 
    {
        transform: rotate(0deg);
    }
    to 
    {
        transform: rotate(360deg);
    }
}
