@import "../../Utilities/Colors.scss";

nav
{
    position: relative;
    display: flex;
    background-color: $color-white0;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 2;
    user-select: none;

    > .container
    {
        width: 300px;
        display: flex;
        align-items: center;
        flex-direction: column;

        header
        {
            width: 100%;
            display: flex;
            justify-content: center;
            padding: 30px 0 15px 0;
            position: sticky;
            top: 0;
            background-color: $color-white0;

            .container
            {
                width: 200px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                h2 
                {
                    display: block;
                }

                .character 
                {
                    height: 48px;
                    width: 48px;
                    margin: 0;
                    cursor: pointer;
                    -webkit-tap-highlight-color: transparent;
                }
            }
        }

        .items
        {
            display: flex;
            flex-direction: column;
            gap: 5px;
            margin-bottom: 5px;
        }

        .items.bottom
        {
            margin-top: auto;
            margin-bottom: 30px;
        }
    }
}

@media only screen and (max-width: 1023px)
{
    nav 
    {
        position: absolute;
        width: 100%;
        height: 100%;
        left: -100%;
        transition: left 0.3s;

        &.open
        {
            left: 0;
        }
    }
}

@media only screen and (max-width: 767px)
{
    nav 
    {
        justify-content: center;

        .container
        {
            align-items: stretch;

            header
            {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 30px 0;
                position: sticky;
                top: 0;

                h2 
                {
                    display: block;
                }

                .character 
                {
                    height: 48px;
                    width: 48px;
                    margin: 0;
                }
            }            
        }
    }
}

// Dark theme
.page.theme-dark nav
{
    background-color: $color-neutral900;
    color: $color-white0;

    header
    {
        background-color: $color-neutral900;
    }
}