@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');
@import "./Utilities/Colors.scss";

body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    flex-direction: column;
}

html, body, #root {
    margin: 0;
    height: 100%;
    min-height: 100%;
}

input {
    font-family: 'Poppins', sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

$color-main: #FF9D00;
$color-main-background: #FF9D002B;