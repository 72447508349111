@import "../../../Utilities/colors";

.button
{
	all: unset;
	cursor: pointer;
	font-weight: 500;
	color: white;
	user-select: none;
	margin: 6px;
	-webkit-tap-highlight-color: transparent;
	box-shadow: 0 0 0 2px $color-white0T;
	transition: all 0.1s;
	display: flex;
	gap: 8px;
	justify-content: center;
	align-items: center;
	flex-wrap: nowrap;
}
	.button.mode_primary.color_primary:disabled,
	.button.mode_primary.color_danger:disabled,
	.button.mode_primary.color_success:disabled,
	.button.mode_secondary.color_primary:disabled,
	.button.mode_secondary.color_danger:disabled,
	.button.mode_secondary.color_success:disabled,
	.button.mode_link.color_success:disabled,
	.button.mode_link.color_danger:disabled,
	.button.mode_link.color_success:disabled,
	.button.mode_primary.color_primary:disabled:hover,
	.button.mode_primary.color_danger:disabled:hover,
	.button.mode_primary.color_success:disabled:hover,
	.button.mode_secondary.color_primary:disabled:hover,
	.button.mode_secondary.color_danger:disabled:hover,
	.button.mode_secondary.color_success:disabled:hover,
	.button.mode_link.color_success:disabled:hover,
	.button.mode_link.color_danger:disabled:hover,
	.button.mode_link.color_success:disabled:hover
	{
		background-color: $color-neutral200 !important;
		color: $color-neutral400 !important;
		box-shadow: 0 0 0 2px $color-white0T;
	}
	.button_content
	{
		white-space: nowrap;
	}
	.button.size_small
	{
		height: 32px;
		font-size: 12px;
		padding: 0 18px;
		border-radius: 12px;
		line-height: 12px;
	}
		.button.size_small.flag_noContent
		{
			width: 32px;
			padding: 0;
		}
	.button.size_medium
	{
		height: 36px;
		font-size: 13px;
		padding: 0 20px;
		border-radius: 12px;
		line-height: 13px;
	}
		.button.size_medium.flag_noContent
		{
			width: 36px;
			padding: 0;
		}
	.button.size_large
	{
		height: 44px;
		font-size: 15px;
		padding: 0 22px;
		border-radius: 12px;
		line-height: 15px;
	}
		.button.size_large.flag_noContent
		{
			width: 44px;
			padding: 0;
		}

	.button.mode_primary
	{
		color: $color-white0;
	}
		.button.mode_primary.color_primary
		{
			background-color: $color-primary500;
		}
			.button.mode_primary.color_primary:focus-visible,
			.button.mode_primary.color_primary:hover
			{
				box-shadow: 0 0 0 4px $color-primary100;
			}
			.button.mode_primary.color_primary:active
			{
				background-color: $color-primary400;
			}
		.button.mode_primary.color_danger
		{
			background-color: $color-danger500;
		}
			.button.mode_primary.color_danger:focus-visible,
			.button.mode_primary.color_danger:hover
			{
				box-shadow: 0 0 0 4px $color-danger100;
			}
			.button.mode_primary.color_danger:active
			{
				background-color: $color-danger400;
			}
		.button.mode_primary.color_success
		{
			background-color: $color-success500;
		}
			.button.mode_primary.color_success:focus-visible,
			.button.mode_primary.color_success:hover
			{
				box-shadow: 0 0 0 4px $color-success100;
			}
			.button.mode_primary.color_success:active
			{
				background-color: $color-success400;
			}

	/* .button.mode_secondary
	{
		
	} */
		.button.mode_secondary.color_primary
		{
			background-color: $color-primary50;
			color: $color-primary500;
		}
			.button.mode_secondary.color_primary:focus-visible,
			.button.mode_secondary.color_primary:hover
			{
				box-shadow: 0 0 0 4px $color-primary50;
			}
			.button.mode_secondary.color_primary:active
			{
				background-color: $color-primary100;
			}
		.button.mode_secondary.color_danger
		{
			background-color: $color-danger50;
			color: $color-danger500;
		}
			.button.mode_secondary.color_danger:focus-visible,
			.button.mode_secondary.color_danger:hover
			{
				box-shadow: 0 0 0 4px $color-danger50;
			}
		.button.mode_secondary.color_success
		{
			background-color: $color-success50;
			color: $color-success500;
		}
			.button.mode_secondary.color_success:focus-visible,
			.button.mode_secondary.color_success:hover
			{
				box-shadow: 0 0 0 4px $color-success50;
			}

	.button.mode_link
	{
		background-color: $color-white0T;
	}
		.button.mode_link.color_primary
		{
			color: $color-primary500;
		}
			.button.mode_link.color_primary:focus-visible,
			.button.mode_link.color_primary:hover
			{
				box-shadow: 0 0 0 4px $color-primary50;
			}
		.button.mode_link.color_danger
		{
			color: $color-danger500;
		}
			.button.mode_link.color_danger:focus-visible,
			.button.mode_link.color_danger:hover
			{
				box-shadow: 0 0 0 4px $color-danger50;
			}
		.button.mode_link.color_success
		{
			color: $color-success500;
		}
			.button.mode_link.color_success:focus-visible,
			.button.mode_link.color_success:hover
			{
				box-shadow: 0 0 0 4px $color-success50;
			}

// Dark theme
.page.theme-dark .button.mode_link 
{
    color: $color-white0
}