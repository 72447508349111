@import "../../Utilities/Colors.scss";

.rangeCheck {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 20px;
    
    .container {
        border-radius: 30px;
        padding: 30px;
        box-sizing: border-box;
        width: 100%;
        max-width: 924px;
        display: flex;
        flex-direction: column;
        gap: 5px;

        &:nth-of-type(2) {
            width: auto;
        }

        header, > .item label {
            display: flex;
            flex-direction: row;
            gap: 75px;
            padding: 0 10px;
            text-transform: uppercase;
            font-weight: bold;
            opacity: 0.2;
        }

        > .item {
            display: flex;
            gap: 20px;
            padding: 10px;
            align-items: center;
            border-radius: 22px;

            &[data-status=error] {
                background-color: $color-danger50;
            }

            label {
                display: none;
                width: 85px;
                padding: 0;
            }

            .input {
                width: 75px;
                font-family: "Courier New";
                font-weight: bold;
            }

            span {
                height: 44px;
                display: flex;
                align-items: center;
                width: 75px;
                font-family: "Courier New";
                font-weight: bold;
            }

            .button {
                margin: 0;
            }
        }

        .output {
            margin-bottom: 10px;

            .item {
                padding: 10px;
                display: flex;
                gap: 20px;
                align-items: center;

                &[data-status=ok] {
                    .status {
                        color: $color-success500;
                    }
                }

                &[data-status=error] {
                    color: $color-danger500;
                }

                .status {
                    margin-left: auto;
                    width: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}

@media only screen and (max-width: 430px)
{
    .rangeCheck .container{
        header {
            display: none
        }

        > .item {
            flex-wrap: wrap;
            gap: 10px;
    
            > .input:nth-of-type(1) {
                margin-right: auto;
            }
    
            > div {
                opacity: 0;
                pointer-events: none;
                flex: 1;
                min-width: 100%;
                height: 0;
            }

            > span {
                height: 20px;
            }

            > span:first-of-type {
                height: 44px;
            }

            label {
                display: flex;
            }
        }
    }
}