@import "../../../Utilities/colors";

.hintInput
{
	all: unset;
	position: relative;
	user-select: none;
	-webkit-tap-highlight-color: transparent;
	display: flex;

	.input
	{
		flex: 1;
		width: 100%;
	}

	&.focus .input 
	{
		border: none;
		box-shadow: 
			0 0 0 2px $color-primary500, 
			0 0 0 6px $color-primary100;
	}

	> .options 
	{
		position: absolute;
		background-color: $color-white0;
		min-width: 100%;
		left: 0;
		top: calc(100% + 10px);
		box-sizing: border-box;
		padding: 7px;
		border-radius: 12px;
		box-shadow: 
			0 0 1px 0 $color-neutral300, 
			0 3px 10px 0 $color-black0T100;
		z-index: 1;

		> .option 
		{
			all: unset;
			cursor: pointer;
			border-radius: 10px;
			height: 40px;
			display: flex;
			align-items: center;
			padding: 0 12px;
			width: 100%;
			box-sizing: border-box;

			&:focus-visible
			{
				background-color: $color-primary50;
				color: $color-primary500;
			}

			> div 
			{
				display: flex;
				align-items: center;
				gap: 7px;
			}
		}
	}
}

// Dark theme
.page.theme-dark .hintInput
{
	.field
	{
		background-color: $color-neutral900;
		box-shadow: 0 0 0 2px $color-neutral800;

		> .icon 
		{
			color: $color-white0;
		}

		&:hover {
			box-shadow: 
				0 0 0 2px $color-neutral500, 
				0 0 0 6px $color-neutral800;
		}
    }

	&:focus
	{
		.field 
		{
			box-shadow: 
				0 0 0 2px $color-neutral300, 
				0 0 0 6px $color-neutral800;
		}
	}

	&.focus .input 
	{
		box-shadow: 
				0 0 0 2px $color-neutral300, 
				0 0 0 6px $color-neutral800;
	}

	> .options 
	{
		background-color: $color-neutral900;
		box-shadow: 
			0 0 1px 0 $color-black0, 
			0 3px 10px 0 $color-black0T100;

		> .option 
		{
			color: $color-neutral400;

			&:focus-visible
			{
				background-color: $color-neutral1000;
				color: $color-white0;
			}
		}
	}
}