@import "../../../Utilities/Colors.scss";

.displaySumup
{
  width: 540px;
  max-width: 100%;
  margin: auto;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;

  .graph
  {
    width: 300px;
    height: 200px;
    display: flex;
    align-items: flex-end;

    .bars
    {
      width: 100%;
      height: 160px;
      display: flex;
      padding: 0 30px;
      box-sizing: border-box;
      gap: 15px;
      align-items: flex-end;

      .bar
      {
        flex: 1;
        background-color: red;  
        border-radius: 13px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        &.small
        {
          > .label
          {
            top: -85px;
          }

          > .content
          {
            top: -50px;
          }
        }

        > .label
        {
          position: absolute;
          color: $color-primary500;
          top: -30px;
        }

        > .content
        {
          position: absolute;
          top: 9px;
          display: flex;
          flex-direction: column;
          align-items: center;

          > .unit
          {
            line-height: 11px;
            font-size: 11px;
          }
        }

        &:nth-child(1)
        {
          background-color: $color-primary500;

          > .content
          {
            color: $color-white0;
          }

          &.small > .content
          {
            color: $color-primary500;
          }
        }

        &:nth-child(2)
        {
          background-color: $color-primary50;

          > .content
          {
            color: $color-primary500;
          }
        }
      }
    }
  }

  .savings
  {
    padding: 0 30px;
    width: 180px;
    display: flex;
    flex-direction: column;

    &.saved
    {
      .text .title
      {
        color: $color-success500;
        background-color: $color-success50;
      }
      .amount
      {
        color: $color-success500;
      }
    }

    &.loss
    {
      .text .title
      {
        color: $color-danger500;
        background-color: $color-danger50;
      }

      .amount
      {
        color: $color-danger500;
      }
    }

    .text
    {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .title
      {
        font-weight: 700;
        padding: 3px 13px;
        border-radius: 13px;

        > svg
        {
          margin-left: 5px;
        }
      }

      .description
      {
        font-size: 10px;
        padding: 3px;
        color: $color-neutral600;
      }
    }
    
    .amount
    {
      padding-left: 3px;
      display: flex;
      gap: 6px;
      margin-top: 20px;

      > .value
      {
        font-weight: 700;
      }
    }
  }
}

// Dark theme
.page.theme-dark .displaySumup 
{
  .graph
  {
    .bars
    {
      .bar
      {
        > .label
        {
          color: $color-white0;
        }

        &:nth-child(2)
        {
          background-color: #0064e822;

          > .content
          {
            color: $color-white0;
          }
        }
      }
    }
  }

  .savings
  {
    &.saved
    {
      .text .title
      {
        background-color: #2BAB2822;
      }
    }

    &.loss
    {
      .text .title
      {
        background-color: #E02D3322;
      }
    }
  }
}

@media only screen and (max-width: 767px)
{
  .displaySumup
  {
    align-items: center;
    flex-direction: column;
    gap: 30px;

    .graph
    {
      width: 240px;
      max-width: 100%;
      
      .bars
      {
        padding: 0;
      }
    }

    .savings
    {
      width: 240px;
      max-width: 100%;
    }
  }
}