@import "../../../Utilities/colors";

.priceInput
{
	all: unset;
	position: relative;
	user-select: none;
	-webkit-tap-highlight-color: transparent;
	display: flex;

	.field
	{
		background-color: $color-white0;
		box-shadow: 0 0 0 2px $color-neutral200;
		padding: 0px 6px 0px 16px;
		border-radius: 12px;
		font-size: 15px;
		height: 44px;
		border: none;
		transition: all 0.1s;
		cursor: pointer;
		display: flex;
		align-items: center;
		flex: 1;

		&:hover {
			box-shadow: 
				0 0 0 2px $color-neutral300, 
				0 0 0 6px $color-neutral100;
		}

		> .valueText {
			flex: 1
		}

		> .valueNumber {
			color: $color-primary500;
			background-color: $color-primary50;
			padding: 5px 10px;
			border-radius: 9px;
		}

		> .currency {
			color: $color-primary500;
			padding: 5px 10px;
		}
	}

	&.focus .field 
	{
		border: none;
		box-shadow: 
			0 0 0 2px $color-primary500, 
			0 0 0 6px $color-primary100;
	}

	> .options 
	{
		position: absolute;
		background-color: $color-white0;
		min-width: 100%;
		left: 0;
		top: calc(100% + 10px);
		box-sizing: border-box;
		padding: 7px;
		border-radius: 12px;
		box-shadow: 
			0 0 1px 0 $color-neutral300, 
			0 3px 10px 0 $color-black0T100;
		z-index: 1;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		> .button
		{
			width: 25%;
			box-sizing: border-box;
			background-color: $color-white0;
			color: $color-primary500;
			height: 44px;
			font-weight: 500;
			// border-radius: 0;

			&:hover
			{
				background-color: $color-primary50;
				box-shadow: none;
			}
		}
	}
}

@media only screen and (max-width: 767px)
{
    .priceInput
    {
		> .options
		{
			z-index: 1;
			position: fixed;
			bottom: 0px;
			top: unset;
			left: 0px;
			width: 100%;
		}
    }
}

// Dark theme
.page.theme-dark .priceInput
{
	.field
	{
		background-color: $color-neutral900;
		box-shadow: 0 0 0 2px $color-neutral800;

		&:hover {
			box-shadow: 
				0 0 0 2px $color-neutral500, 
				0 0 0 6px $color-neutral800;
		}

		> .valueNumber {
			color: $color-primary500;
			background-color: $color-primary50;
		}

		> .currency {
			color: $color-white0;
		}
	}

	&.focus .field 
	{
		box-shadow: 
			0 0 0 2px $color-neutral300, 
			0 0 0 6px $color-neutral800;
	}

	> .options 
	{
		background-color: $color-neutral900;
		box-shadow: 
			0 0 1px 0 $color-black0, 
			0 3px 10px 0 $color-black0T100;

		> .button
		{
			background-color: $color-neutral900;
			color: $color-white0;

			&:hover
			{
				background-color: $color-neutral1000;
			}
		}
	}
}