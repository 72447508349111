@import "../../Utilities/Colors.scss";

.remoteList {
    display: flex;
    flex-direction: column;

    .nav {
        margin-top: 20px;
        display: flex;
        align-items: center;

        .pageNumber {
            text-align: center;
            flex: 1;
        }
    }

    .items {
        min-height: 0;
        box-shadow: 0 5px 30px $color-primary50;
        border-radius: 12px;
        overflow: auto;
        scrollbar-width: none;

        .table {
            display: table;
            width: 100%;
            max-height: 100%;
            border-radius: 12px;

            .header {
                display: table-row;
                position: sticky;
                top: 0;
        
                .column {
                    display: table-cell;
                    padding: 8px;
                    background-color: $color-primary50;
                    color: $color-primary500;

                    &:first-child {
                        border-radius: 12px 0 0 0;
                        padding-left: 16px;
                    }

                    &:last-child {
                        border-radius: 0 12px 0 0;
                        padding-right: 16px;
                    }
                }
            }

            .item {
                display: table-row;
        
                .column {
                    display: table-cell;
                    padding: 8px;

                    &:first-child {
                        padding-left: 16px;
                    }

                    &:last-child {
                        padding-right: 16px;
                    }
                }
            }
        }
    }
}