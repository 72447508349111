@import "../../../Utilities/colors";

.fileInput
{
	all: unset;
	display: flex;
	align-items: center;
	gap: 10px;
	font-size: 15px;
	height: 44px !important;
	border: none;
	cursor: pointer;
	border-radius: 12px;
	transition: all 0.1s;

	&:focus {
		border: none;
		box-shadow: 
			0 0 0 2px $color-primary500, 
			0 0 0 6px $color-primary100;
	}

	> input {
		display: none;
	}

	> button {
		white-space: nowrap;
	}

	> .name {
		white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
	}
}

// Dark theme
.page.theme-dark .input
{
	background-color: $color-neutral900;
	box-shadow: 0 0 0 2px $color-neutral800;

	&::placeholder {
		color: $color-neutral600;
	}

	&:hover {
		box-shadow: 
			0 0 0 2px $color-neutral500, 
			0 0 0 6px $color-neutral800;
	}

	&:focus {
		box-shadow: 
			0 0 0 2px $color-neutral300, 
			0 0 0 6px $color-neutral800;
	}
}