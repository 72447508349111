@import "../../Utilities/Colors.scss";

.navbar
{
    user-select: none;
    background-color: $color-white0;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    flex-shrink: 0;
	-webkit-tap-highlight-color: transparent;

    > div
    {        
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        font-size: 10px;
        color: $color-neutral300;
        flex: 1;
        transition: 0.2s all;

        &.active 
        {
            color: $color-primary500;
        }

        svg
        {
            height: 20px;
        }
    }
}

@media only screen and (min-width: 1024px)
{
    .navbar 
    {
        display: none;
    }
}

.page.theme-dark .navbar
{
    background-color: $color-neutral900;
    color: $color-white0;

    > div
    {        
        color: $color-neutral600;

        &.active 
        {
            color: $color-white0;
        }
    }
}