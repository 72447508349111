@import "../../Utilities/Colors.scss";

.locationSuggestions
{
    display: flex;

    .container 
    {
        border-radius: 30px;
        padding: 30px;
        box-sizing: border-box;
        width: 100%;
        // max-width: 924px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        position: relative;

        .emptyList
        {
            padding: 15px;
        }

        .item
        {
            user-select: none;
            border-radius: 18px;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            box-sizing: content-box;
            border: 1px solid $color-white0T;
            transition: border 0.2s;

            &:hover {
                // background-color: $color-primary50;
                // color: $color-primary500;
                border: 1px solid #E6E6E6;
            }

            > button {
                flex-shrink: 0;
            }

            > header 
            {
                flex: 1;
	            -webkit-tap-highlight-color: transparent;
                padding: 10px 10px;
                width: 200px;
                
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                cursor: pointer;
                -webkit-tap-highlight-color: transparent;
                position: relative;
                gap: 10px;

                &::after 
                {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 1px;
                    bottom: 0;
                    left: 0;
                    background-color: $color-white0T;
                    transition: background-color 0.2s;
                }

                > img {
                    width: 200px;
                    height: 300px;
                    object-fit: cover;
                    border-radius: 8px;

                    &[src=""] {
                        background-color: $color-primary100;
                        content:url("data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==");
                    }
                }

                > .icon
                {
                    width: 48px;
                    height: 48px;
                    font-size: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 10px;

                    > img {
                        width: 48px;
                        height: 48px;
                        object-fit: contain;
                    }
                }

                > .status 
                {
                    background-color: $color-success50;
                    color: $color-success500;
                    margin-left: 10px;
                    margin-right: 10px;
                    font-size: 12px;
                    padding: 5px 12px;
                    border-radius: 100px;

                    &.unresolved 
                    {
                        background-color: $color-danger50;
                        color: $color-danger500;
                    }
                }

                > span
                {
                    font-weight: 400;
                    font-size: 15px;
                    margin: 0;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                > .name {
                    font-weight: 500;
                    font-size: 22px;
                }

                > .mail {
                    font-size: 12px;
                    color: $color-primary400;
                }
            }

            > .buttons {
                display: flex;
                flex-direction: row;
            }
        }
    }
}