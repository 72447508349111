@import "../../Utilities/Colors.scss";

.page
{
    display: flex;
    background-color: $color-neutral50;
    height: 100%;
    flex-direction: column;

    > div:first-child
    {
        display: flex;
        flex: 1;
        overflow: hidden;
        position: relative;

        > .content 
        {
            overflow: auto;
            padding: 50px;
            flex: 1;
            display: flex;
            flex-direction: column;

            > header
            {
                height: 44px;
                display: flex;
                flex-direction: row;
                gap: 10px;
                align-items: center;
                margin-bottom: 30px;
                
                > h2 
                {
                    margin: 0;
                    line-height: 32px;
                    font-size: 32px;
                    font-weight: 500;
                    user-select: none;
                }

                > .button
                {
                    min-width: 32px;
                    border-radius: 100%;
                }
            }

            > div
            {
                padding-bottom: 100px;

                > .subpage > div
                {
                    display: flex;
                    align-items: flex-start;
                    flex-direction: row;
                    flex-wrap: wrap;
                    gap: 30px;
                }

                > .container 
                {
                    background-color: $color-white0;
                }
            }
        }
    }

    .hotAction
    {
        z-index: 1;
        position: absolute;
        right: 20px;
        bottom: 20px;

        .button
        {
            border-radius: 100%;
            height: 48px;
            width: 48px;
            font-size: 20px;
        }
    }
}

@media only screen and (max-width: 1023px)
{
    .page
    {
        > div:first-child
        {
            
            > .content 
            {
                padding: 50px 50px;

                > h2 
                {
                    padding: 0 50px;
                }

                > div > .subpage > div
                {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }
        }

        .hotAction
        {
            right: 20px;
            bottom: 84px;
        }
    }
}

@media only screen and (max-width: 767px)
{
    .page
    {
        > div:first-child
        {
            > .content 
            {
                padding: 35px 20px;
            }
        }
    }
}

// Dark theme
.page.theme-dark
{
    background-color: $color-neutral1000;
    color: $color-white0;

    > div:first-child
    {
        > .content 
        {
            > div
            {
                > .container 
                {
                    background-color: $color-neutral900;
                }
            }
        }
    }
}