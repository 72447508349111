@import "../../../Utilities/Colors.scss";

.displayAreaChart
{
  width: 540px;
  max-width: 100%;
  margin: auto;
  flex: 1;
  display: flex;
  flex-direction: column;

  .graph
  {
    min-height: 150px;
    z-index: 1;
    width: 100%;
    flex: 1;
  }

  .total
  {
    box-sizing: border-box;
    width: calc(100% - 10px);
    margin: 0 5px;

    background-color: $color-primary50;
    padding: 10px;
    color: $color-primary500;
    border-radius: 0 0 13px 13px;
    position: relative;
    top: -6px;
    display: flex;
    justify-content: center;

    .sum
    {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      gap: 7px;

      .value
      {
        font-weight: 700;
      }

      .unit
      {
        font-weight: 400;
        font-size: 13px;
      }
    }
  }

  .recharts-tooltip-wrapper
  {
    all: unset;
  }

  .customTooltip
  {
    background-color: $color-white0;
    padding: 15px 20px;
    border-radius: 20px;
    box-shadow: 
			0 0 1px 0 $color-neutral300, 
			0 3px 10px 0 $color-black0T100;
    outline: none;
    
    .label
    {
      color: $color-primary500;
    }

    .value
    {
      font-weight: 700;
      color: black;
    }
  }
}

// Dark theme
.page.theme-dark .displayAreaChart 
{
  .total
  {
    background-color: #0064e822;
    color: $color-white0;
  }
}

@media only screen and (max-width: 767px)
{
  .displayPieChart
  {
    align-items: center;
    flex-direction: column;

    .detail
    {
      padding: 0;
      max-width: 100%;
    }
  }
}