@import "../../../Utilities/Colors.scss";

.displayHeatMatrix
{
  width: 540px;
  max-width: 100%;
  margin: auto;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  h3 {
    width: 100%;
    margin: 0;
    line-height: 14px;
    font-size: 14px;
    margin-bottom: 20px;
    font-weight: 400;
  }

  .graph
  {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, 10px);
    gap: 2px;

    .cell {
      width: 10px;
      height: 10px;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .popup {
        display: none;
        position: absolute;
        bottom: 20px;

        background-color: $color-white0;
        padding: 15px 20px;
        border-radius: 20px;
        box-shadow: 
          0 0 1px 0 $color-neutral300, 
          0 3px 10px 0 $color-black0T100;
        z-index: 1;
    
        h2 {
          color: $color-primary500;
        }

        p {
          font-weight: 700;
          color: black;
          white-space: nowrap;
        }
      }

      &:hover .popup {
        display: block;
      }
    }
  }
}

// Dark theme
.page.theme-dark .displayHeatMatrix 
{
  
}

@media only screen and (max-width: 767px)
{
  .displayHeatMatrix
  {
    
  }
}