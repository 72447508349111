@import "../../Utilities/Colors.scss";

.globalSuccess
{
    display: flex;
    flex-direction: row;
    padding: 0 50px;
    gap: 20px;
    height: 40px;
    font-size: 14px;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;
    background-color: $color-success500;
    color: $color-white0;
    box-sizing: border-box;
    top: -45px;
    opacity: 0;
    transition: all 0.3s;

    &.visible
    {
        opacity: 1;
        top: 0;
    }

    > .icon
    {
        width: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    > .message
    {
        flex: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        min-width: 0;
    }

    > .close
    {
        margin-left: auto;
        cursor: pointer;
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-success500;
        transition: 0.2s all;
        -webkit-tap-highlight-color: transparent;

        &:hover
        {
            background-color: $color-success400;
        }
    }
}

@media only screen and (max-width: 767px)
{
    .globalSuccess
    {
        padding: 0 20px;
        gap: 15px;
    }
}