@import "../../../Utilities/colors";

.select
{
	all: unset;
	position: relative;
	user-select: none;
	-webkit-tap-highlight-color: transparent;

	.field
	{
		background-color: $color-white0;
		box-shadow: 0 0 0 2px $color-neutral200;
		padding: 0px 0 0px 16px;
		border-radius: 12px;
		font-size: 15px;
		height: 44px;
		border: none;
		transition: all 0.1s;
		cursor: pointer;
		display: flex;
		align-items: center;
		
		> *:first-child 
		{
			flex: 1;
			display: flex;
			align-items: center;
			gap: 7px;
		}

		> .icon 
		{
			width: 44px;
			height: 44px;
			display: flex;
			justify-content: center;
			align-items: center;
			color: $color-primary500;
		}

		&:hover {
			box-shadow: 
				0 0 0 2px $color-neutral300, 
				0 0 0 6px $color-neutral100;
		}
	}

	&:focus
	{
		.field 
		{
			border: none;
			box-shadow: 
				0 0 0 2px $color-primary500, 
				0 0 0 6px $color-primary100;
		}
	} 

	> input 
	{
		display: none;
	}

	> .options 
	{
		position: absolute;
		background-color: $color-white0;
		min-width: 100%;
		left: 0;
		top: calc(100% + 10px);
		box-sizing: border-box;
		padding: 7px;
		border-radius: 12px;
		box-shadow: 
			0 0 1px 0 $color-neutral300, 
			0 3px 10px 0 $color-black0T100;
		z-index: 1;

		> .option 
		{
			position: relative;
			cursor: pointer;
			border-radius: 10px;
			height: 40px;
			display: flex;
			align-items: center;
			padding: 0 12px;
			white-space: nowrap;

			&.active 
			{
				background-color: $color-primary50;
				color: $color-primary500;
			}

			> div 
			{
				display: flex;
				align-items: center;
				gap: 7px;
			}
		}
	}

	&.flag_optionsLayoutGrid > .options
	{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		> .option
		{
			box-sizing: border-box;
			min-width: 40px;
			display: flex;
			justify-content: center;
			flex: 1;
		}
	}

	&::placeholder {
		color: $color-neutral400;
		opacity: 1;
	}
}

// Dark theme
.page.theme-dark .select
{
	.field
	{
		background-color: $color-neutral900;
		box-shadow: 0 0 0 2px $color-neutral800;

		> .icon 
		{
			color: $color-white0;
		}

		&:hover {
			box-shadow: 
				0 0 0 2px $color-neutral500, 
				0 0 0 6px $color-neutral800;
		}
    }

	&:focus
	{
		.field 
		{
			box-shadow: 
				0 0 0 2px $color-neutral300, 
				0 0 0 6px $color-neutral800;
		}
	}

	> .options 
	{
		background-color: $color-neutral800;
		box-shadow: 
			0 0 1px 0 $color-black0, 
			0 3px 10px 0 $color-black0T100;
		
		> .option 
		{
			color: $color-neutral400;

			&.active 
			{
				background-color: $color-neutral1000;
				color: $color-white0;
			}
		}
	}
}