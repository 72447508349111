@import "../../Utilities/Colors.scss";

.scans
{
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
    flex: 1;
    padding-bottom: 0 !important;
    overflow: hidden;

    .tile
    {
        // flex: 1;
        max-width: 100%;
        box-sizing: border-box;
        padding: 30px;
        background-color: $color-white0;
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        position: relative;
        max-height: 100%;
        overflow: hidden;

        > h3
        {
            margin: 0;
            line-height: 24px;
            font-size: 24px;
            margin-bottom: 10px;
            font-weight: 500;
        }

        > .subtitle
        {
            margin: 0;
            line-height: 14px;
            font-size: 14px;
            margin-bottom: 20px;
            font-weight: 400;
        }

        .remoteList {
            width: 300px;
            flex: 1;
            min-height: 0px;
        }
    }
}