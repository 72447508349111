@import "../../Utilities/Colors.scss";

.login
{
    position: relative;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    .backgroundImage
    {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .section
    {
        width: 850px;
        position: absolute;
        box-sizing: border-box;
        border-radius: 90px;
        background-color: $color-white0T500;
        backdrop-filter: blur(100px);
        padding: 120px 100px;
        display: flex;
        gap: 70px;

        > * 
        {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }

        .logo > img
        {
            transform: scale(-1, 1);
        }

        form
        {
            gap: 15px;

            header
            {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 15px;

                .icon-background
                {
                    height: 40px;
                    color: $color-primary500;
                    border-radius: 13px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    svg 
                    {
                        height: 16px;
                    }
                }
            }

            .input
            {
                width: 100%;
                max-width: 200px;
            }

            .button
            {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                max-width: 100%;
            }
        }
    }

    .credits
    {
        position: absolute;
        bottom: 20px;
        color: $color-white0;
        font-size: 15px;

        .bold
        {
            display: inline-block;
            margin-right: 5px;
            font-weight: 700;
        }
    }
}



@media only screen and (min-width: 1024px) and (max-height: 768px)
{
    .login
    {
        min-height: 568px;

        .section
        {
            padding: 60px 80px;
            width: 740px;
        }
    }
}

@media only screen and (max-width: 1023px) and (max-height: 568px)
{
    .login
    {
        height: 568px;
    }
}

@media only screen and (max-width: 1023px)
{
    .login
    {
        .section
        {
            border-radius: 40px;
            width: calc(100% - 40px);
            max-width: 350px;
            padding: 100px 20px 35px 20px;

            .logo
            {
                position: absolute;
                width: 130px;
                height: 130px;
                overflow: hidden;
                left: calc(50% - 65px);
                top: -65px;

                > img
                {
                    transform: none;
                }
            }

            form
            {
                width: 100%;
                box-sizing: border-box;
                padding: 0 10px;
            }
        }
    }
}