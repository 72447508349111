$color-primary500: #0064E8;
$color-primary400: #3383ED;
$color-primary300: #66A2F1;
$color-primary200: #99C1F6;
$color-primary100: #CCE0FA;
$color-primary50: #E6F0FD;

$color-danger500: #E02D33;
$color-danger400: #E7575C;
$color-danger300: #ED8186;
$color-danger200: #F4ABAF;
$color-danger100: #FAD5D8;
$color-danger50: #FFECEE;

$color-success500: #2BAB28;
$color-success400: #55BD52;
$color-success300: #7ECF7D;
$color-success200: #A8E0A7;
$color-success100: #D1F2D1;
$color-success50: #E6F7E6;

$color-neutral1000: #1A1A1A;
$color-neutral900: #333333;
$color-neutral800: #4D4D4D;
$color-neutral700: #666666;
$color-neutral600: #808080;
$color-neutral500: #999999;
$color-neutral400: #B3B3B3;
$color-neutral300: #CCCCCC;
$color-neutral200: #E6E6E6;
$color-neutral100: #F2F2F2;
$color-neutral50: #F9F9F9;

$color-black0: #000000;
$color-white0: #ffffff;

$color-white0T: #ffffff00;
$color-white0T100: rgba(255, 255, 255, 0.1);
$color-white0T200: rgba(255, 255, 255, 0.2);
$color-white0T300: rgba(255, 255, 255, 0.3);
$color-white0T400: rgba(255, 255, 255, 0.4);
$color-white0T500: rgba(255, 255, 255, 0.5);
$color-white0T600: rgba(255, 255, 255, 0.6);
$color-white0T700: rgba(255, 255, 255, 0.7);
$color-white0T800: rgba(255, 255, 255, 0.8);
$color-white0T900: rgba(255, 255, 255, 0.9);

$color-black0T: #00000000;
$color-black0T100: rgba(0, 0, 0, 0.1);
$color-black0T200: rgba(0, 0, 0, 0.2);
$color-black0T300: rgba(0, 0, 0, 0.3);
$color-black0T400: rgba(0, 0, 0, 0.4);
$color-black0T500: rgba(0, 0, 0, 0.5);
$color-black0T600: rgba(0, 0, 0, 0.6);
$color-black0T700: rgba(0, 0, 0, 0.7);
$color-black0T800: rgba(0, 0, 0, 0.8);
$color-black0T900: rgba(0, 0, 0, 0.9);

