@import "../../Utilities/Colors.scss";

.modal
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-black0T300;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: 1fr auto 1fr;
    z-index: 2;
    opacity: 1;
    transition: all 0.2s;
    overflow: auto;

    &.hidden
    {
        opacity: 0;

        > .content
        {
            top: 15px;
            transform: scale(0.95);
        }
    }

    > .content
    {
        grid-column: 2;
        grid-row: 2;
        top: 50px;
        margin-top: 50px;
        margin-bottom: 50px;
        background-color: $color-white0;
        border-radius: 40px;
        padding: 50px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        min-width: 300px;
        transform: scale(1);
        transition: all 0.2s;

        .close
        {
            position: absolute;
            top: 38px;
            right: 38px;

            > .button 
            {
                border-radius: 100%;
            }
        }
        
        .button
        {
            margin: 0;
        }

        h2
        {
            margin: 0;
            line-height: 24px;
            font-size: 24px;
            margin-bottom: 20px;
            font-weight: 500;
            user-select: none;
        }

        > .content 
        {
            display: flex;
            flex-direction: column;
            flex: 1;

            > form 
            {
                user-select: none;
                flex: 1;
                display: flex;
                flex-direction: column;
                gap: 20px;

                .formGroup
                {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;

                    label 
                    {
                        height: 25px;

                        .optional
                        {
                            display: inline-block;
                            color: $color-neutral400;
                            font-size: 12px;
                            margin-left: 3px;
                        }
                    }

                    .static {
                        // color: $color-primary500;
                        font-weight: bold;
                        background-color: $color-neutral50;
                        border: 2px solid $color-neutral100;
                        border-radius: 12px;
                        height: 44px;
                        display: flex;
                        align-items: center;
                        padding: 0 15px;
                        pointer-events: all;
                        user-select: text;

                        &.multiLine {
                            padding: 15px;
                            height: auto;
                            max-height: 150px;
                            overflow: auto;
                            align-items: flex-start;
                        }
                    }

                    .formError
                    {
                        background-color: $color-danger50;
                        color: $color-danger500;
                        border-radius: 10px;
                        padding: 5px 15px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        font-size: 14px;
                        gap: 10px;

                        > svg
                        {
                            width: 16px;
                        }
                    }
                }

                .formRow 
                {
                    display: flex;
                    flex-direction: row;
                    gap: 15px;

                    .formGroup
                    {
                        flex: 1;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px)
{
    .modal
    {
        display: flex;
        justify-content: center;
        overflow: auto;
        align-items: flex-start;

        > .content
        {
            width: 100%;
            min-height: calc(100% - 50px);
            top: 50px;
            box-sizing: border-box;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            
            position: relative;
            margin-top: 0px;
            margin-bottom: 50px;
            

            > .content 
            {
                > form 
                {
                    > :last-child {
                        margin-top: auto;
                    }
                }
            }
        }

        &.hidden
        {
            opacity: 0;

            > .content
            {
                top: 100px;
                transform: scale(1);
            }
        }
    }
}

// Dark theme
.page.theme-dark .modal
{
    > .content 
    {
        background-color: $color-neutral900;
    }
}