@import "../../../Utilities/colors";

.emptyList
{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 30px;
    user-select: none;
    max-width: 310px;
    margin: 0 auto;

    header
    {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 30px;
        color: $color-neutral500;

        img 
        {
            width: 150px;
        }
    }

    .button 
    {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
    }
}