@import "../../Utilities/Colors.scss";

.error
{
    position: relative;
    height: 100%;
    width: 100%;

    > * {
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
    }

    > .title 
    {
        top: calc(50vh + 170px);
        font-size: 24px;
        font-weight: 500;
        color: $color-primary500;
    }

    > .message 
    {
        width: 100%;
        top: calc(50vh + 220px);
        font-size: 15px;
        font-weight: 500;
        text-align: center;
        color: $color-neutral400;
    }

    img 
    {
        top: calc(50vh - 110px);
        height: 550px;
        width: 550px;
    }
}