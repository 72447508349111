@import "../../../Utilities/colors";

.input
{
	all: unset;
	background-color: $color-white0;
	box-shadow: 0 0 0 2px $color-neutral200;
	padding: 0px 16px;
	border-radius: 12px;
	font-size: 15px;
	height: 44px;
	border: none;
	transition: all 0.1s;

	&::placeholder {
		color: $color-neutral400;
		opacity: 1;
	}

	&:hover {
		box-shadow: 
			0 0 0 2px $color-neutral300, 
			0 0 0 6px $color-neutral100;
	}

	&:focus {
		border: none;
		box-shadow: 
			0 0 0 2px $color-primary500, 
			0 0 0 6px $color-primary100;
	}
}

// Dark theme
.page.theme-dark .input
{
	background-color: $color-neutral900;
	box-shadow: 0 0 0 2px $color-neutral800;

	&::placeholder {
		color: $color-neutral600;
	}

	&:hover {
		box-shadow: 
			0 0 0 2px $color-neutral500, 
			0 0 0 6px $color-neutral800;
	}

	&:focus {
		box-shadow: 
			0 0 0 2px $color-neutral300, 
			0 0 0 6px $color-neutral800;
	}
}