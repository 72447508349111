@import "../../Utilities/Colors.scss";

.locations
{
    display: flex;

    .container 
    {
        border-radius: 30px;
        padding: 30px;
        box-sizing: border-box;
        width: 100%;
        max-width: 924px;
        display: flex;
        flex-direction: column;

        .emptyList
        {
            padding: 15px;
        }

        .item
        {
            user-select: none;
            border-radius: 18px;
            display: flex;
            flex-direction: column;
            box-sizing:content-box;
            width: 100%;
            border: 1px solid $color-white0T;
            transition: border 0.2s;

            &.expanded
            {
                border: 1px solid $color-neutral200;

                > header::after 
                {
                    background-color: $color-neutral200;
                }

                > .detail 
                {
                    max-height: 860px;
                    padding: 15px;
                    opacity: 1;
                    overflow: initial;
                    z-index: 1;
                }
            }

            > header 
            {
	            -webkit-tap-highlight-color: transparent;
                padding: 15px;
                display: flex;
                flex-direction: row;
                align-items: center;
                cursor: pointer;
                -webkit-tap-highlight-color: transparent;
                position: relative;

                &::after 
                {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 1px;
                    bottom: 0;
                    left: 0;
                    background-color: $color-white0T;
                    transition: background-color 0.2s;
                }

                > .icon
                {
                    width: 48px;
                    height: 48px;
                    font-size: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 10px;

                    > img {
                        width: 48px;
                        height: 48px;
                        object-fit: contain;
                    }
                }

                > .status 
                {
                    background-color: $color-success50;
                    color: $color-success500;
                    margin-left: 10px;
                    margin-right: 10px;
                    font-size: 12px;
                    padding: 5px 12px;
                    border-radius: 100px;

                    &.unresolved 
                    {
                        background-color: $color-danger50;
                        color: $color-danger500;
                    }
                }

                > .expandedIcon
                {
                    width: 30px;
                    height: 30px;
                    color: $color-neutral200;
                    font-size: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                > h2 
                {
                    font-weight: 400;
                    font-size: 15px;
                    margin-left: 15px;
                    flex: 1;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }

            > .detail 
            {
                max-height: 0px;
                transition: height 0.2s, padding 0.2s, opacity 0.2s;
                padding: 0px 15px;
                opacity: 0;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                gap: 15px;

                > .info 
                {
                    display: flex;
                    flex-wrap: wrap;
                    align-content: flex-start;
                    row-gap: 12px;
                }
            
                .button
                {
                    margin: 0;
                }
            }

            .infoGroup 
            {
                width: 100%;

                &.infoGroupW2
                {
                    flex: 1;
                    min-width: 100px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                }

                > .label
                {
                    font-size: 8px;
                    color: $color-neutral700;
                }

                > .value 
                {
                    display: flex;
                    flex-direction: row;
                    align-items: baseline;
                    gap: 4px;

                    > .value 
                    {
                        font-size: 15px;
                        font-weight: 500;
                    }

                    > .unit 
                    {
                        font-size: 12px;
                        font-weight: 300;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px)
{
    .orders
    {
        .container .item
        {
            // &.expanded
            // {
            //     > .detail 
            //     {
            //         mheight: 180px;
            //     }
            // }

            // > .detail 
            // {
            //     > .info 
            //     {
            //         height: 129px;
            //     }
            // }

            .infoGroup 
            {
                // width: 50%;

                &.infoGroupW2
                {
                    width: 100%;
                }
            }
        }
    }
}

.orders + .modal > .content > .content
{
    .row
    {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 50px;

        .column
        {

            flex: 1;
            width: 400px;
            display: flex;
            flex-direction: column;
            gap: 20px;

            img {
                border-radius: 12px;
                aspect-ratio: 2/3;
                object-fit: cover;
                cursor: pointer;
                box-shadow: 0 0 0 rgba(0, 0, 0, 0);
                transition: box-shadow 0.2s;

                &:hover {
                    box-shadow: 0 0 0 10px $color-primary50;
                }
            }
        }
    }
    
}

// Dark theme
.page.theme-dark .orders
{
    .container .item
    {
        border: 1px solid $color-black0T;

        &.expanded
        {
            border: 1px solid $color-neutral800;

            > header::after 
            {
                background-color: $color-neutral800;
            }
        }

        > header 
        {
            &::after 
            {
                background-color: $color-black0T;
            }

            > .cost 
            {
                > .detail
                {
                    color: $color-neutral300;
                }
            }

            > .expandedIcon
            {
                color: $color-neutral800;
            }
        }

        .infoGroup > .label
        {
            color: $color-neutral300;
        }
    }
}