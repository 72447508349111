@import "../../../Utilities/colors";

.switch
{
	all: unset;
	position: relative;
	user-select: none;
	-webkit-tap-highlight-color: transparent;

	.options
	{
		background-color: $color-white0;
		box-shadow: 0 0 0 2px $color-neutral200;
		padding: 4px 4px;
		border-radius: 12px;
		font-size: 15px;
		// height: 44px;
		border: none;
		transition: all 0.1s;
		cursor: pointer;
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		> .option 
		{
			all: unset;	
			position: relative;
			cursor: pointer;
			border-radius: 10px;
			height: 36px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 15px;
			flex: 1;
			color: $color-neutral800;
			transition: 0.2s all;

			> div
			{
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 10px;

				svg
				{
					font-size: 12px;
				}
			}

			&.active
			{
				background-color: $color-primary50;
				color: $color-primary500;
			}

			&:focus-visible
			{
				outline: none;
				color: $color-primary500;

				&::before
				{
					content: "";
					width: 4px;
					height: 4px;
					border-radius: 2px;
					background-color: $color-primary300;
					position: absolute;
					left: calc(50% - 1.5px);
					top: 5px;
				}
			}
		}
	}
}

// Dark theme
.page.theme-dark .switch
{
	.options
	{
		background-color: $color-neutral900;
		box-shadow: 0 0 0 2px $color-neutral800;

		> .option 
		{
			color: $color-white0;

			&.active
			{
				background-color: $color-primary500;
				color: $color-white0;
			}
		}
	}
}