.displayTable
{
  border-spacing: 20px 5px;
  
  .row
  {
    display: table-row;

    &.header
    {
      font-weight: 700;
    }

    .column
    {
      display: table-cell;
    }
  }
}