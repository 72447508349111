@import "../../Utilities/Colors.scss";

.settings 
{
    .nav
    {
        display: flex;
        flex-direction: row;
        gap: 10px;
        margin-bottom: 40px;

        .button 
        {
            margin: 0
        }
    }

    .section 
    {
        background-color: $color-white0;
        border-radius: 30px;
        padding: 40px;
        width: 300px;
        display: flex;
        flex-direction: column;
        
        .button
        {
            margin: 0;
        }

        h3
        {
            margin: 0;
            line-height: 24px;
            font-size: 24px;
            margin-bottom: 20px;
            font-weight: 500;
        }

        form
        {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .formGroup
            {
                display: flex;
                flex-direction: column;
                gap: 5px;

                label 
                {
                    height: 25px;

                    .optional
                    {
                        display: inline-block;
                        color: $color-neutral400;
                        font-size: 12px;
                        margin-left: 3px;
                    }
                }

                .formError
                {
                    background-color: $color-danger50;
                    color: $color-danger500;
                    border-radius: 10px;
                    padding: 5px 15px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    font-size: 14px;
                    gap: 10px;

                    > svg
                    {
                        width: 16px;
                    }
                }
            }
        }

        p 
        {
            padding-left: 20px;
            position: relative;

            &::before
            {
                content: "";
                position: absolute;
                height: 100%;
                border-radius: 4px;
                width: 4px;
                left: 0;
                background-color: $color-primary500;
            }
        }
    }

    .board
    {
        .members 
        {
            .item
            {
                display: flex;
                flex-direction: row;
                border-bottom: 1px solid $color-neutral100;
                // padding: 5px 0;
                height: 36px;
                align-items: center;

                &:last-child
                {
                    border-bottom: none;
                }

                > svg 
                {
                    color: $color-primary500;
                    font-size: 12px;
                    margin-left: 10px;
                }

                .button 
                {
                    margin-left: auto;
                }
            }
        }

        .invite
        {
            margin-top: 20px;

            .button
            {
                box-sizing: border-box;
                width: 100%;
            }
        }

        .modal .content
        {
            max-width: 767px;
            box-sizing: border-box;

            > * 
            {
                overflow-wrap: break-word;
            }
        }
    }
}

@media only screen and (max-width: 767px)
{
    .settings 
    {
        .section
        {
            width: 100%;
            box-sizing: border-box;
        }
    }
}

// Dark theme
.page.theme-dark .settings 
{
    .section 
    {
        background-color: $color-neutral900;
    }

    .board .members .item
    {
        border-bottom: 1px solid $color-neutral800;
        
        &:last-child
        {
            border-bottom: none;
        }
    }
}