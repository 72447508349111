@import "../../Utilities/Colors.scss";

.confirm
{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 30px;
    user-select: none;
    width: 300px;

    header
    {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 30px;
        color: $color-neutral500;

        img 
        {
            width: 100px;
        }
    }

    .actions
    {
        display: flex;
        flex-direction: row;
        gap: 15px;

        .button 
        {
            flex: 1
        }
    }
}

@media only screen and (max-width: 767px)
{
    .confirm
    {
        width: 100%;
        flex: 1;
        align-items: center;

        header
        {
            margin-top: 30px;
            width: 300px;
        }

        .actions
        {
            margin-top: auto;
            width: 100%;
        }
    }
}