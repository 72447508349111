@import "../../Utilities/Colors.scss";

.summary
{
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;

    .tile
    {
        // flex: 1;
        max-width: 100%;
        box-sizing: border-box;
        padding: 30px;
        background-color: $color-white0;
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        position: relative;

        > .select
        {
            position: absolute;
            right: 30px;
            top: 30px;
            z-index: 1;
        }

        > h3
        {
            margin: 0;
            line-height: 24px;
            font-size: 24px;
            margin-bottom: 10px;
            font-weight: 500;
        }

        > .subtitle
        {
            margin: 0;
            line-height: 14px;
            font-size: 14px;
            margin-bottom: 20px;
            font-weight: 400;
        }

        > .group 
        {
            display: flex;
            flex-direction: column;
            flex: 1;

            > h4
            {
                margin: 0;
                line-height: 14px;
                font-size: 14px;
                margin-bottom: 20px;
                font-weight: 400;
            }
        }

        > .note {
            width: 530px;
            max-width: calc(100% - 10px);
            padding: 10px 15px;
            border-radius: 13px;
            box-sizing: border-box;
            margin: 5px;

            > svg {
                margin-right: 7px;
            }

            &[data-type=danger] {
                background-color: $color-danger50;
                color: $color-danger500;
            }

            &[data-type=warning] {
                background-color: #FFFBEC;
                color: #99801B;
            }

            &[data-type=info] {
                background-color: $color-primary50;
                color: $color-primary500;
            }
        }
    }
}

.summaryLoading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;

    h2 {
        color: $color-neutral800;
    }
}

@media only screen and (max-width: 767px)
{
    .summary
    {
        flex-direction: column;
    }
}

// Dark theme
.page.theme-dark .summary 
{
    .tile 
    {
        background-color: $color-neutral800;
    }
}

.page.theme-dark .summaryLoading {
    h2 {
        color: $color-neutral700;
    }
}