.displayPieChart
{
  display: flex;
  flex-direction: row;
  gap: 20px;

  .detail
  {
    padding: 20px;
    width: 320px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .item
    {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      .category
      {
        display: flex;
        flex-direction: row;
        font-size: 14px;
        align-items: center;
        gap: 10px;
        flex: 1;
        min-width: 0;

        .icon
        {
          width: 32px;
          height: 32px;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          .bg {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            opacity: 0.09;
          }
        }

        .name
        {
          flex: 1;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }

    .info
    {
      margin-left: auto;

      .sum
      {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        gap: 7px;

        .value
        {
          font-weight: 700;
        }

        .unit
        {
          font-weight: 400;
          font-size: 13px;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px)
{
  .displayPieChart
  {
    align-items: center;
    flex-direction: column;

    .detail
    {
      padding: 0;
      max-width: 100%;
    }
  }
}