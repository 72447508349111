@import "../../../Utilities/colors";

.nav-item
{
    display: flex;
    align-items: center;
    gap: 20px;
    width: 200px;
    padding: 7px;
    border-radius: 17px;
    cursor: pointer;
	-webkit-tap-highlight-color: transparent;
    transition: all 0.1s;

    .icon-background
    {
        display: inline-block;
        width: 40px;
        height: 40px;
        background: $color-primary50;
        color: $color-primary500;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;
        flex-shrink: 0;
        transition: all 0.1s;
        

        svg 
        {
            height: 15px;
        }

        font-size: 15px;
    }

    .text 
    {
        font-size: 15px;
    }

    &:hover 
    {
        background-color: $color-primary50;
        color: $color-primary500;
    }
}

@media only screen and (max-width: 767px)
{
    .nav-item
    {
        width: 100%;
        box-sizing: border-box;
    }
}

// Dark theme
.page.theme-dark .nav-item
{
    .icon-background
    {
        background: $color-neutral1000;
        color: $color-white0;
    }

    &:hover 
    {
        background-color: $color-neutral1000;
        color: $color-white0;
    }
}